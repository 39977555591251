import React, { Component } from 'react';

import './styles.css';

import Header from '../../components/Header';

import ItemComplement from '../../components/ItemComplement';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { Creators as ProductCreator } from '../../store/ducks/product';

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import iconComerAqui from '../../assets/image/icon.png'

import Currency from '../../utils/currency';

class ScreenRemoveComplement extends Component {

    constructor(props) {
        super(props);
        
        window.scrollTo(0, 0);

        let note = [];

        if (props.location.note) {
            note = props.location.note;
        }

        this.state = {
            checkedItems: [],
            remove: [],
            activeButton: false,
            note,
            adicionais: props.location.adicionais,
            telaAnterior: props.location.telaAnterior,
        }

        this.handleChecked = this.handleChecked.bind(this)
    }

    handleChecked(e, price = 0, id) {
        const item = e.target.name;
        const isChecked = e.target.checked;
      
        this.setState(prevState => {
          const checkedItems = [...prevState.checkedItems];
      
          if (isChecked) {
            // Adiciona o item marcado
            checkedItems.push({ id, name: item, value: price });
          } else {
            // Remove o item desmarcado
            const updatedCheckedItems = checkedItems.filter(item => item.id !== id);
            checkedItems.length = 0; // Limpa o array original
            checkedItems.push(...updatedCheckedItems); // Copia de volta os itens filtrados
          }
          
      
          return { checkedItems };
        });
        
      }
      


    async componentDidMount() {
        this.setState({
            remove: this.props.product.product.ingredients,
            activeButton: true
        });


    }

    handleBack = () => {
        // let { note, adicionais, telaAnterior } = this.state;

        // this.props.history.push({
        //     pathname: `/${telaAnterior}`,
        //     note,
        //     adicionais,
        //     telaAnterior: "remove"
        // });

        // this.props.history.goBack();        

        if (localStorage.getItem('@comer-aqui/typeOrder') == "ENTREGA") {
            //   this.props.history.push({ pathname: '/' })
            this.props.history.push( '/' + localStorage.getItem('@comer-aqui/empresaUrl') )
        } else {
            this.props.history.push( '/' + localStorage.getItem('@comer-aqui/empresaUrl') + '/?id=' + localStorage.getItem('@comer-aqui/authorization'));

        }
    }

    render() {  
        const _handleSubmit = () => {
            const { note, adicionais } = this.state;
            const { grupoObservacao, inComplemento } = this.props.product.product.attrs;

            // console.log("lanche add = ", this.state)

            this.props.setRemoveComplement(this.att);

            this.setState({remove: this.state.checkedItems});

            if (grupoObservacao && grupoObservacao.length > 0) {
                this.props.history.push({ pathname: '/product-note', remove: this.state.checkedItems, grupoObservacao });
                return;
            } else {
                if (adicionais && adicionais.length > 0 && inComplemento === 'S') {
                    this.props.history.push({ pathname: '/add', note, adicionais, remove: this.state.checkedItems, telaAnterior: "remove" });
                    return;
                } else {
                    this.props.history.push({ pathname: '/product', note, remove: this.state.checkedItems, telaAnterior: "remove" });
                    return;
                }
            }
        }        

        return (
            <>
                <Header apenasLogo={true}/>
                
                <div className="col-12 d-flex align-items-center justify-content-center mt-2">
                    {!this.props.product.product.url ?
                        <img src={iconComerAqui} alt='Comer Aqui' className='img-fluid' style={{ /*opacity: 0.35,*/ height: 72, objectFit: 'contain' }} />
                        :
                        <img height={100} src={this.props.product.product.url} alt={this.props.product.product.alt} className="product-img mt-2" />
                    }
                </div>
                <div className="col-md-12 col-xl-12 col-sm-12 col-12 d-flex align-items-center justify-content-center">
                    {localStorage.getItem('@comer-aqui/typeOrder') == 'MESA' ? (
                        <h6>{this.props.product.product.name} - {Currency.formatterValue.format(this.props.product.product.vlPreco)}</h6>
                    ) : (
                        <>
                            {this.props.product.product.promotion_price > 0 &&                    
                            <h6>{this.props.product.product.name} - {Currency.formatterValue.format(this.props.product.product.promotion_price)}</h6>}
                            {(this.props.product.product.promotion_price == 0) || (this.props.product.product.promotion_price == null) &&
                            <h6>{this.props.product.product.name} - {Currency.formatterValue.format(this.props.product.product.price)}</h6>}                        
                        </>
                    )}
                </div>
                {this.props.product.product.description && (
                    <div className="product-description">
                        <h6>{this.props.product.product.description}</h6>
                    </div>
                )}
                <div className="title-complement">
                    <span>Retirar ingrediente?</span>
                </div>                
                <div>
                    <ul className="list-group mb-5">
                        {/* {console.log( "remover ", this.state.checkedItems)} */}
                        {
                            this.state.remove !== undefined  && this.state.remove.length > 0 ?
                                this.state.remove.map(item => (
                                    <ItemComplement
                                        key={item.id}
                                        id={item.id}
                                        name={item.name}
                                        onChecked={this.handleChecked}
                                    />
                                ))
                                : <span className="title-complement-not-found"> Nenhum ingrediente encontrado :( </span>
                        }
                    </ul>
                    <div className="fixed-bottom">
                        <div className="row">
                            <div className="col-md-6 col-xl-6 col-sm-6 col-6 p-0">
                                <button className="btn btn-dark btn-block btn-go-back" onClick={this.handleBack}><FaArrowLeft /> Voltar </button>
                            </div>
                            <div className="col-md-6 col-xl-6 col-sm-6 col-6 p-0">
                                <button className="btn btn-primary btn-block" disabled={!this.state.activeButton} onClick={() => { _handleSubmit() }}> Próximo <FaArrowRight /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({ product: state.product });

const mapDispatchToProps = dispatch => bindActionCreators(ProductCreator, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenRemoveComplement)
